import { useSelector } from "react-redux";
import { translateComponent } from "../../utils/services/translate";

//scss
import "./ticketConfirmationDialog.scss";
export function TicketConfirmationDialogPlaceHolder({
  countdownValidationSeconds,
  isOpen,
  onClose,
  onConfirm,
}) {
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  if (!isOpen) return null;
  return (
    <div className="confirmation-dialog bg-white text-dark text-start h-100 pt-4">
      <div className="content-container">
        <div className="container">
          <h2>
            {translateComponent(
              translations,
              language,
              "ticket",
              "confirmDialogh2"
            )}
          </h2>
          <p>
            {" "}
            {translateComponent(
              translations,
              language,
              "ticket",
              "confirmDialogp1"
            )}
            {countdownValidationSeconds / 60}
            {translateComponent(
              translations,
              language,
              "ticket",
              "confirmDialogTimep1"
            )}
          </p>

          <p className="text-danger">
            <b>
              {translateComponent(
                translations,
                language,
                "ticket",
                "confirmDialogPlaceHolderText1"
              )}
            </b>{" "}
            {translateComponent(
              translations,
              language,
              "ticket",
              "confirmDialogPlaceHolderText2"
            )}
          </p>
        </div>
      </div>
      <div className="col-12 pe-2 ps-2 mb-3">
        <button className="btn btn-danger w-100 " onClick={onClose}>
          {translateComponent(
            translations,
            language,
            "ticket",
            "confirmDialogCancelButton"
          )}
        </button>
      </div>
    </div>
  );
}
