import React from "react";
import { useSelector } from "react-redux";

// utils
import { translateComponent } from "../../../utils/services/translate";
import currencyLabel from "../../../utils/helper/currencyLabel";
import moment from "moment";
import "moment/locale/sv";
import "moment/locale/en-gb";
import "moment/locale/fi";

// scss
import "./basketSummaryComponent.scss";
function BasketSummaryComponent() {
  const basket = useSelector((state) => state.Order.basket);
  const language = useSelector((state) => state.Layout.language);
  const projectData = useSelector((state) => state.Project.project);
  const translations = useSelector((state) => state.Layout.translations);

  const formatDateWithDay = (date, locale) => {
    const formattedDate = moment
      .utc(date, moment.ISO_8601)
      .locale(locale)
      .format("dddd, YYYY-MM-DD HH:mm");
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  };

  if (basket.basketValid) {
    return (
      <div>
        <div className="table-responsive">
          <table className="table basket-summary-table">
            <thead className="bg-light">
              <tr>
                <th scope="col">
                  <p className="text-m p-0 m-0">
                    {" "}
                    {translateComponent(
                      translations,
                      language,
                      "basketSummaryComponent",
                      "product"
                    )}
                  </p>
                </th>
                <th scope="col" className="text-center">
                  <p className="text-m p-0 m-0">
                    {translateComponent(
                      translations,
                      language,
                      "basketSummaryComponent",
                      "count"
                    )}
                  </p>
                </th>
                <th scope="col" className="text-center">
                  <p className="text-m p-0 m-0">
                    {translateComponent(
                      translations,
                      language,
                      "basketSummaryComponent",
                      "price"
                    )}
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {basket.products.map((product, index) => {
                const productTranslation = product.translations.find(
                  (t) => t.locale === language
                );
                return (
                  <tr key={index} className="">
                    <td>
                      <p className="text-extra-bold text-m mb-0  p-0">
                        {productTranslation.name}
                      </p>
                      <p className=" small p-0 m-0">
                        {productTranslation.description}
                      </p>

                      {product.type === "reservedSeatTicket" ? (
                        <p className=" small p-0 m-0">
                          {formatDateWithDay(
                            product.departure.departureTime,
                            language
                          )}
                        </p>
                      ) : null}
                    </td>
                    <td className="text-center">
                      <p className="text-m">{product.quantity}</p>
                    </td>
                    <td className="text-center">
                      <p className=" text-m">
                        {" "}
                        {product.price}{" "}
                        {currencyLabel(language, projectData.currency)}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default BasketSummaryComponent;
