import React, { useEffect, useState } from "react";
import moment from "moment";

// redux;
import { useSelector, useDispatch } from "react-redux";

//components
import { translateComponent } from "../../utils/services/translate";
import { TicketTemplate } from "./ticketTemplate";
import { TicketTemplatePlaceHolder } from "./ticketTemplatePlaceholder";

// redux
import { updateTicket } from "../../redux/actions";

// api
import {
  activateTicket,
  fraudProtection,
  getTicket,
} from "../../utils/api/restClient";

// helpers
import {
  saveToken,
  getToken,
  getActivationColor,
} from "./ticketLocalStorageHelper";
// helpers
import formatDateWithDay from "../../utils/helper/dateFormatter";

//sccs
import "react-responsive-carousel/lib/styles/carousel.min.css";

// import "./ticketListCarusell.scss";
import "./countDownTicket.scss";

function CountDownTicket({ ticket }) {
  // internal state
  const [countdown, setCountdown] = useState(null);
  const [countdownInactiveTime, setCountdownInactiveTime] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [informationOpen, setInformationOpen] = useState(false);
  const [validationOpen, setValidationOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [fraud, setFraud] = useState(false);
  const [fraudProtectionExecuted, setFraudProtectionExecuted] = useState(false);
  const [activationColor, setActivationColor] = useState(null);
  // redux
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const dispatch = useDispatch();

  // functions
  const validateTicket = async (ticket, dispatch) => {
    await activateTicket(ticket.referenceId).then((res) => {
      if (res !== undefined && res.token !== undefined) {
        saveToken(
          ticket.referenceId,
          ticket.countdownValidationSeconds,
          res.token,
          res.color
        );
        setActivationColor(res.color);
        dispatch(updateTicket(res.ticket));
        const handleVisibilityChange = () => {
          if (document.visibilityState === "hidden") {
            handleAppBecomesInactive();
          } else {
            handleAppResumed();
          }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
          document.removeEventListener(
            "visibilitychange",
            handleVisibilityChange
          );
        };
      } else {
        setErrorOpen(true);
      }
    });
  };
  const fraudProtectionCheck = async (referenceId, token) => {
    await fraudProtection(referenceId, token).then((res) => {
      if (res === undefined) {
        setFraud(true);
        setFraudProtectionExecuted(true);
      } else {
        if (res.status === "valid") {
          setFraud(false);
          setFraudProtectionExecuted(true);
          let color = getActivationColor(referenceId);
          if (color !== null) {
            setActivationColor(color);
          }
        } else {
          setFraud(true);
          setFraudProtectionExecuted(true);
        }
      }
    });
  };

  // dialog functions
  function handleActivateClick() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  function handleErrorClose() {
    setErrorOpen(false);
  }
  function handleInformationClose() {
    setInformationOpen(false);
  }
  function handleValidationClose() {
    setValidationOpen(false);
  }
  function handleDialogConfirm() {
    validateTicket(ticket, dispatch);
    setDialogOpen(false);
  }
  function ticketCounterTicketText(ticket) {
    if (ticket.maxAllowedAttempts > 1) {
      let ticketLeftValue =
        ticket.maxAllowedAttempts - ticket.validationAttempts.length;
      return (
        <>
          <div onClick={() => setValidationOpen(true)}>
            <span className="span-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-question-circle"
                viewBox="0 -3 20 20"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
              </svg>
            </span>
            {ticket.maxAllowedAttempts === 100 ? (
              <span className="span-link  text-m text-bold ps-1">
                {translateComponent(
                  translations,
                  language,
                  "validationInformationDialog",
                  "unlimitedTicketLinkText"
                )}
              </span>
            ) : (
              <span className="span-link  text-m text-bold ps-1">
                {ticketLeftValue}{" "}
                {ticketLeftValue === 1
                  ? translateComponent(
                      translations,
                      language,
                      "validationInformationDialog",
                      "ticketCounterTripText"
                    )
                  : translateComponent(
                      translations,
                      language,
                      "validationInformationDialog",
                      "ticketCounterTripsText"
                    )}
              </span>
            )}
          </div>
        </>
      );
    }
  }
  function getHeaderText(ticket, lang) {
    let ticketTranslation = ticket.translations.find((x) => x.locale === lang);
    if (ticketTranslation === undefined || ticketTranslation === null) {
      if (
        ticketTranslation.description === "" ||
        ticketTranslation.description === null
      ) {
        return (
          <>
            <p className="text-extra-bold mt-2  mb-2 pt-1 text-left">
              {ticket.name}
            </p>
            {ticket.productType === "reservedSeatTicket" ? (
              <p className="p-0 m-0 text-m text-bold">
                <b>
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "ticket",
                    "departureTime"
                  )}
                  :
                </b>{" "}
                {formatDateWithDay(ticket.departureTime, lang)}
              </p>
            ) : null}
          </>
        );
      } else {
        return (
          <>
            <p className="text-extra-bold mt-2  mb-2 pt-1 text-left">
              {ticket.name} - {ticket.description}
            </p>
            {ticket.productType === "reservedSeatTicket" ? (
              <p className="p-0 m-0 text-m text-bold">
                <b>
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "ticket",
                    "departureTime"
                  )}
                  :
                </b>{" "}
                {formatDateWithDay(ticket.departureTime, lang)}
              </p>
            ) : null}
          </>
        );
      }
    } else {
      if (
        ticketTranslation.name !== ticket.name ||
        ticketTranslation.description !== ticket.description
      ) {
        if (
          ticketTranslation.description === "" ||
          ticketTranslation.description === null
        ) {
          return (
            <>
              <p className="text-extra-bold p-0 m-0 pt-1 text-left">
                {ticket.name}
              </p>
              <p className="text-m p-0 m-0 pb-1">
                ( {ticketTranslation.name} )
              </p>
              {ticket.productType === "reservedSeatTicket" ? (
                <p className="p-0 m-0 text-m text-bold">
                  <b>
                    {" "}
                    {translateComponent(
                      translations,
                      language,
                      "ticket",
                      "departureTime"
                    )}
                    :
                  </b>{" "}
                  {formatDateWithDay(ticket.departureTime, lang)}
                </p>
              ) : null}
            </>
          );
        } else {
          return (
            <>
              <p className="text-extra-bold p-0 m-0 pt-1 text-left">
                {ticket.name} - {ticket.description}
              </p>
              <p className="text-m p-0 m-0 pb-1">
                ( {ticketTranslation.name} - {ticketTranslation.description} )
              </p>
              {ticket.productType === "reservedSeatTicket" ? (
                <p className="p-0 m-0 text-m text-bold">
                  <b>
                    {" "}
                    {translateComponent(
                      translations,
                      language,
                      "ticket",
                      "departureTime"
                    )}
                    :
                  </b>{" "}
                  {formatDateWithDay(ticket.departureTime, lang)}
                </p>
              ) : null}
            </>
          );
        }
      }
      if (
        ticketTranslation.description === "" ||
        ticketTranslation.description === null
      ) {
        return (
          <>
            <p className="text-extra-bold mt-2  mb-2 pt-1 text-left">
              {ticket.name}
            </p>
            {ticket.productType === "reservedSeatTicket" ? (
              <p className="p-0 m-0 text-m text-bold">
                <b>
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "ticket",
                    "departureTime"
                  )}
                  :
                </b>{" "}
                {formatDateWithDay(ticket.departureTime, lang)}
              </p>
            ) : null}
          </>
        );
      } else {
        return (
          <>
            <p className="text-extra-bold m-0 p-0 mt-2   text-left">
              {ticket.name} ({ticket.description})
            </p>
            {ticket.productType === "reservedSeatTicket" ? (
              <p className="p-0 m-0 text-m text-bold">
                <b>
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "ticket",
                    "departureTime"
                  )}
                  :
                </b>{" "}
                {formatDateWithDay(ticket.departureTime, lang)}
              </p>
            ) : null}
          </>
        );
      }
    }
  }

  // effects
  useEffect(() => {
    if (ticket.status === "activated") {
      let token = getToken(ticket.referenceId);

      fraudProtectionCheck(ticket.referenceId, token);
      if (token !== null) {
        setCountdown(ticket.activatedCountdownSecondsRemaining + 3);
      } else {
        setCountdown(null);
      }
    } else {
    }
  }, [ticket]);

  useEffect(() => {
    if (countdown !== null) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown - 1 <= 0) {
            clearInterval(timer);
            window.location.reload();
          } else {
            return prevCountdown - 1;
          }
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown]);

  const handleAppBecomesInactive = () => {
    setCountdownInactiveTime(Date.now());
  };

  const handleAppResumed = () => {
    const lastInactiveTime = countdownInactiveTime;
    const currentTime = Date.now();

    if (lastInactiveTime) {
      const timeElapsed = currentTime - lastInactiveTime;
      setCountdownInactiveTime(null);

      // check if time is more than 5 min (300 000 milliseconds)
      if (timeElapsed > 300000) {
        window.location.reload();
      }
      if (timeElapsed > 5000) {
        try {
          getTicket(ticket.referenceId)
            .then((res) => {
              try {
                if (res !== undefined && res.name) {
                  updateTicket(res);
                  setCountdown(res.activatedCountdownSecondsRemaining);
                } else {
                  window.location.reload();
                }
              } catch (innerError) {
                console.error(
                  "Error processing the ticket response:",
                  innerError
                );
                window.location.reload();
              }
            })
            .catch((error) => {
              console.error("Error fetching the ticket:", error);
              window.location.reload();
            });
        } catch (error) {
          console.error("Unexpected error in handleAppResumed:", error);
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    if (ticket.status === "activated") {
      const handleVisibilityChange = () => {
        if (document.visibilityState === "hidden") {
          handleAppBecomesInactive();
        } else {
          handleAppResumed();
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    } else {
    }
  }, [countdownInactiveTime, ticket]);

  const renderCountdown = () => {
    if (countdown !== null && countdown !== undefined) {
      const minutes = Math.floor(countdown / 60);
      const seconds = countdown % 60;
      if (!isNaN(minutes)) {
        return (
          <>
            <span className="countdown-text w-100 d-block pt-2">
              {minutes.toString().padStart(2, "0")}:
              {seconds.toString().padStart(2, "0")}
            </span>
            <span className="moving-square d-block">...</span>
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  if (ticket.validationType === "countdown") {
    if (ticket.status === "valid") {
      const cutoffDate = moment("2024-07-11 14:00");
      const currentDate = moment();
      // If the project ID is 4 and the current date and time is before 2024-07-08 01:00,
      // display a different component. Otherwise, show the standard component.
      if (ticket.projectId === "4" && currentDate.isBefore(cutoffDate)) {
        return (
          <>
            <TicketTemplatePlaceHolder
              ticket={ticket}
              onActivateClick={handleActivateClick}
              handleDialogConfirm={handleDialogConfirm}
              handleDialogClose={handleDialogClose}
              handleErrorClose={handleErrorClose}
              handleInformationClose={handleInformationClose}
              handleValidationInformationClose={handleValidationClose}
              validationOpen={validationOpen}
              dialogOpen={dialogOpen}
              errorOpen={errorOpen}
              informationOpen={informationOpen}
              background={"bg-lightblue"}
              showActivateButton={true}
              ticketHeader={(ticket) => <>{getHeaderText(ticket, language)}</>}
              ticketStatus={(ticket) => (
                <div className="ticket-status bg-success w-100 text-extra-bold pr-5">
                  <p className="m-0 p-2">
                    {" "}
                    {translateComponent(
                      translations,
                      language,
                      "ticket",
                      "valid"
                    )}{" "}
                    1
                  </p>
                </div>
              )}
              ticketInformation={(ticket) => (
                <div className="ticket-information w-100 text-dark">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 pt-2 m-0 p-0 mb-4">
                        {ticketCounterTicketText(ticket)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </>
        );
      } else {
        return (
          <>
            <TicketTemplate
              ticket={ticket}
              onActivateClick={handleActivateClick}
              handleDialogConfirm={handleDialogConfirm}
              handleDialogClose={handleDialogClose}
              handleErrorClose={handleErrorClose}
              handleInformationClose={handleInformationClose}
              handleValidationInformationClose={handleValidationClose}
              validationOpen={validationOpen}
              dialogOpen={dialogOpen}
              errorOpen={errorOpen}
              informationOpen={informationOpen}
              background={"bg-lightblue"}
              showActivateButton={true}
              ticketHeader={(ticket) => <>{getHeaderText(ticket, language)}</>}
              ticketStatus={(ticket) => (
                <div className="ticket-status bg-success w-100 text-extra-bold pr-5">
                  <p className="m-0 p-2">
                    {" "}
                    {translateComponent(
                      translations,
                      language,
                      "ticket",
                      "valid"
                    )}{" "}
                  </p>
                </div>
              )}
              ticketInformation={(ticket) => (
                <div className="ticket-information w-100 text-dark">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 pt-2 m-0 p-0 mb-4">
                        {ticketCounterTicketText(ticket)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </>
        );
      }
    }
    if (ticket.status === "activated") {
      if (fraud && fraudProtectionExecuted) {
        return (
          <TicketTemplate
            ticket={ticket}
            onActivateClick={handleActivateClick}
            handleDialogConfirm={handleDialogConfirm}
            handleDialogClose={handleDialogClose}
            handleErrorClose={handleErrorClose}
            handleInformationClose={handleInformationClose}
            handleValidationInformationClose={handleValidationClose}
            validationOpen={validationOpen}
            dialogOpen={dialogOpen}
            errorOpen={errorOpen}
            informationOpen={informationOpen}
            fraudProtection={true}
            background={"bg-danger"}
            ticketHeader={(ticket) => getHeaderText(ticket, language)}
            ticketStatus={(ticket) => (
              <div className="ticket-status bg-dark text-white w-100 text-extra-bold pr-5">
                <p className="m-0 p-2">
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "ticket",
                    "fraud"
                  )}{" "}
                </p>
              </div>
            )}
            ticketInformation={(ticket) => (
              <div className="ticket-information w-100 text-extra-bold text-dark">
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-center pt-4 pb-3 ps-0 pe-0">
                      <button
                        className="btn btn-secondary text-m text-dark w-100  p-3"
                        onClick={() => setInformationOpen(true)}
                      >
                        {translateComponent(
                          translations,
                          language,
                          "ticket",
                          "ticketInformation"
                        )}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        );
      } else {
        if (fraudProtectionExecuted) {
          let bg = "pulsating-background";
          if (process.env.REACT_APP_ENVIRONMENT === "TEST") {
            bg = "pulsating-background"; // todo: update to bg-danger
          }
          return (
            <>
              <TicketTemplate
                ticket={ticket}
                onActivateClick={handleActivateClick}
                handleDialogConfirm={handleDialogConfirm}
                handleDialogClose={handleDialogClose}
                handleErrorClose={handleErrorClose}
                handleInformationClose={handleInformationClose}
                handleValidationInformationClose={handleValidationClose}
                validationOpen={validationOpen}
                dialogOpen={dialogOpen}
                errorOpen={errorOpen}
                showActivateButton={false}
                background={bg}
                fraudProtection={false}
                activationColor={activationColor}
                ticketHeader={(ticket) => getHeaderText(ticket, language)}
                ticketStatus={(ticket) => (
                  <div className="ticket-status bg-success w-100 text-extra-bold pr-5">
                    <p className="m-0 p-2">
                      {" "}
                      {translateComponent(
                        translations,
                        language,
                        "ticket",
                        "ticketActivated"
                      )}{" "}
                    </p>
                  </div>
                )}
                ticketInformation={(ticket) => (
                  <div className="ticket-information w-100 text-extra-bold pr-5 text-dark">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 text-center">
                          <div className="row ">
                            <div className="col-12 bg-light">
                              {renderCountdown()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              />
            </>
          );
        } else {
          return null;
        }
      }
    }
    if (
      ticket.status === "refunded" ||
      ticket.status === "pendingRefund" ||
      ticket.status === "refundFailed"
    ) {
      return (
        <TicketTemplate
          ticket={ticket}
          onActivateClick={handleActivateClick}
          handleDialogConfirm={handleDialogConfirm}
          handleDialogClose={handleDialogClose}
          handleErrorClose={handleErrorClose}
          handleInformationClose={handleInformationClose}
          handleValidationInformationClose={handleValidationClose}
          validationOpen={validationOpen}
          dialogOpen={dialogOpen}
          errorOpen={errorOpen}
          informationOpen={informationOpen}
          background={"bg-danger"}
          ticketHeader={(ticket) => getHeaderText(ticket, language)}
          ticketStatus={(ticket) => (
            <div className="ticket-status bg-dark text-white w-100 text-extra-bold pr-5">
              <p className="m-0 p-2">
                {" "}
                {translateComponent(
                  translations,
                  language,
                  "ticket",
                  "refunded"
                )}{" "}
              </p>
            </div>
          )}
          ticketInformation={(ticket) => (
            <div className="ticket-information w-100 text-extra-bold text-dark">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center pt-4 pb-3 ps-0 pe-0">
                    <button
                      className="btn btn-secondary text-m text-dark w-100  p-3"
                      onClick={() => setInformationOpen(true)}
                    >
                      {translateComponent(
                        translations,
                        language,
                        "ticket",
                        "ticketInformation"
                      )}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      );
    }
    if (ticket.status === "expired") {
      return (
        <>
          <TicketTemplate
            ticket={ticket}
            onActivateClick={handleActivateClick}
            handleDialogConfirm={handleDialogConfirm}
            handleDialogClose={handleDialogClose}
            handleErrorClose={handleErrorClose}
            handleInformationClose={handleInformationClose}
            dialogOpen={dialogOpen}
            handleValidationInformationClose={handleValidationClose}
            validationOpen={validationOpen}
            errorOpen={errorOpen}
            informationOpen={informationOpen}
            background={"bg-danger"}
            ticketHeader={(ticket) => getHeaderText(ticket, language)}
            ticketStatus={(ticket) => (
              <div className="ticket-status bg-dark text-white w-100 text-extra-bold pr-5">
                <p className="m-0 p-2">
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "ticket",
                    "expired"
                  )}{" "}
                </p>
              </div>
            )}
            ticketInformation={(ticket) => (
              <div className="ticket-information w-100 text-extra-bold text-dark">
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-center pt-2 pb-3 ps-0 pe-0">
                      <button
                        className="btn btn-outline-secondary text-m text-white w-100  "
                        onClick={() => setInformationOpen(true)}
                      >
                        {translateComponent(
                          translations,
                          language,
                          "ticket",
                          "ticketInformation"
                        )}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        </>
      );
    }
    if (ticket.status === "used") {
      return (
        <>
          <TicketTemplate
            ticket={ticket}
            handleDialogConfirm={handleDialogConfirm}
            handleDialogClose={handleDialogClose}
            handleErrorClose={handleErrorClose}
            handleInformationClose={handleInformationClose}
            dialogOpen={dialogOpen}
            handleValidationInformationClose={handleValidationClose}
            validationOpen={validationOpen}
            errorOpen={errorOpen}
            informationOpen={informationOpen}
            background={"bg-danger"}
            showActivateButton={false}
            ticketHeader={(ticket) => getHeaderText(ticket, language)}
            ticketStatus={(ticket) => (
              <div className="ticket-status bg-dark w-100 text-extra-bold pr-5">
                <p className="m-0 p-2">
                  {" "}
                  {translateComponent(
                    translations,
                    language,
                    "ticket",
                    "not_valid"
                  )}{" "}
                </p>
              </div>
            )}
            ticketInformation={(ticket) => (
              <div className="ticket-information w-100 text-extra-bold text-dark">
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-center pt-4 pb-3 ps-0 pe-0">
                      <button
                        className="btn btn-secondary text-m text-dark w-100  p-3"
                        onClick={() => setInformationOpen(true)}
                      >
                        {translateComponent(
                          translations,
                          language,
                          "ticket",
                          "ticketInformation"
                        )}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        </>
      );
    }
  } else {
    return null;
  }
}

export default CountDownTicket;
