import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import moment from "moment-timezone";
// redux
import {
  setLoading,
  setLoadingMessage,
  setProductList,
  setSelectedProjectSlug,
  setSelectedProject,
  setLanguage,
  setShowNavigationMenu,
  setSelectedPaymentOption,
} from "../../redux/actions";

// Components
import BookingFormComponent from "../../components/booking/bookingFormComponent";
import BookingFormV2 from "../../components/booking/bookingFormComponentV2";
import { LoadingComponent } from "../../components/layout/loading/LoadingComponent";
import { translatePage } from "../../utils/services/translate";
import LanguageComponent from "./LanguageComponent";
import ProjectEndMessageHtml from "./projectEndMessageHtml";

// LocalStorage
import {
  setDefaultProject,
  setPreferedLanguage,
  getPreferedLanguage,
} from "../../utils/localStorage";

// API
import {
  getProductsBySlug,
  getProjectsBySlug,
} from "../../utils/api/restClient";

// scss
import "./landingPageByProject.scss";

function LandingPageByProject() {
  const { isAuthed } = useAuth();
  const { project } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // states
  const loading = useSelector((state) => state.Layout.loading);
  const { language, translations } = useSelector((state) => state.Layout);
  const projectData = useSelector((state) => state.Project.project);
  const productData = useSelector((state) => state.Product.product_list);
  const basketData = useSelector((state) => state.Order.basket);
  // componentStates
  const [apiLoaded, setApiLoaded] = useState(1);
  const [projectNotStarted, setProjectNotStarted] = useState(false);
  const [projectEnded, setprojectEnded] = useState(false);
  const [projectStartTime, setProjectStartTime] = useState(false);

  const openMyPages = (dispatch, navigate, isAuthed) => {
    if (isAuthed) {
      navigate("/home/orders");
    } else {
      dispatch(setShowNavigationMenu(true));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchApiData = async (param2) => {
      try {
        dispatch(setProductList(null));
        dispatch(setLoadingMessage("Laddar..", "Loading.."));
        let res = null;
        if (projectData === null) {
          res = await getProjectsBySlug(param2);

          if (res.statusCode === 400) {
            navigate("/404");
          }
        } else {
          res = projectData;
        }
        let started = new Date() > new Date(res.startTime);
        let ended = new Date() > new Date(res.endTime);
        if (started === false || ended === true) {
          if (started === false) {
            setProjectNotStarted(true);
            setProjectStartTime(res.startTime);
          }
          if (ended === true) {
            setDefaultProject(res);
            setprojectEnded(true);
            dispatch(setSelectedProject(res));
            setPreferedLanguage(getPreferedLanguage());
            dispatch(setLanguage(getPreferedLanguage()));
          }
        } else {
          if (res.name !== undefined) {
            dispatch(setSelectedProject(res));
            setDefaultProject(res);
            dispatch(setSelectedPaymentOption(res.paymentOptions[0]));
            setPreferedLanguage(getPreferedLanguage());
            dispatch(setLanguage(getPreferedLanguage()));

            const res2 = await getProductsBySlug(param2);
            if (res2.length > 0) {
              let visibleProducts = res2.filter((product) => {
                let productEndDate = new Date(product.visibilityEndDate);
                return productEndDate > new Date();
              });

              dispatch(setProductList(visibleProducts));
              setApiLoaded(true);
            } else {
              dispatch(setProductList(null));
              dispatch(setLoading(false));
              setApiLoaded(true);
            }
          } else {
            dispatch(setProductList(null));
            dispatch(setLoading(false));
            setApiLoaded(true);
            navigate("/404");
          }
        }
      } catch (error) {
        console.error("API call failed:", error);
        navigate("/404");
      } finally {
        dispatch(setLoading(false));
        dispatch(setSelectedProjectSlug(param2));
      }
    };
    if (basketData.total === 0) {
      fetchApiData(project);
    }
  }, [navigate, dispatch]);

  if (loading) {
    return <LoadingComponent />;
  }

  if (projectEnded) {
    return (
      <div className="mt-4">
        <div className="container min-vh-100">
          <div className="row justify-content-center min-vh-100">
            <div className="col-auto">
              <div className="bookingFormContainer mt-5">
                <p
                  id={"page_landingPage_description"}
                  className="text-m d-block mb-2"
                >
                  {translatePage(
                    translations,
                    language,
                    "landingPage",
                    "description"
                  )}
                  <span
                    id={"page_landingPage_myPages"}
                    className="span-link text-extra-bold text-m"
                    onClick={() => openMyPages(dispatch, navigate, isAuthed)}
                  >
                    {translatePage(
                      translations,
                      language,
                      "landingPage",
                      "myPages"
                    )}
                  </span>
                  <p></p>
                  <LanguageComponent></LanguageComponent>
                </p>
                <ProjectEndMessageHtml
                  html={
                    projectData.translations.find(
                      (translation) => translation.locale === language
                    )?.projectEndMessageHtml
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (projectNotStarted) {
    return (
      <div className="mt-4">
        <div className="container min-vh-100">
          <div className="row justify-content-center min-vh-100">
            <div className="col-auto">
              <div className="startTimeDiv mt-5">
                <LanguageComponent></LanguageComponent>
                {language === "sv-SE" ? (
                  <>
                    <h1 className="bg-light p-4">
                      Biljettförsäljningen startar snart!
                    </h1>
                    <p>
                      Vi vill informera alla våra entusiastiska besökare att
                      biljettförsäljningen för evenemanget ännu inte har
                      startat. Men oroa er inte, ni kommer inte att missa er
                      chans att säkra era platser. Märk era kalendrar för den{" "}
                      <strong>
                        {moment(projectStartTime).format("YYYY-MM-DD HH:mm")}
                      </strong>
                      , då kommer biljetterna officiellt att släppas till
                      försäljning.
                    </p>
                  </>
                ) : (
                  <>
                    <h1 className="bg-light p-4">
                      Ticket Sales Starting Soon!
                    </h1>
                    <p>
                      We would like to inform all our eager attendees that
                      ticket sales for the event have not yet begun. But worry
                      not, you won't miss your chance to secure your spots. Mark
                      your calendars for{" "}
                      <strong>
                        {" "}
                        {moment(projectStartTime).format("YYYY-MM-DD HH:mm")}
                      </strong>
                      , when tickets will officially go on sale.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (apiLoaded) {
    if (project === "diggiloobussen" || project === "diggiloobussentest1") {
      return (
        <div>
          {productData ? (
            <div className="mt-4">
              <div className="container min-vh-100">
                <div className="row justify-content-center min-vh-100">
                  <div className="col-auto">
                    <div className="bookingFormContainer mt-5">
                      <h1 id={"page_landingPage_title"} className="d-inline">
                        {projectData?.translations?.find(
                          (translation) => translation.locale === language
                        )?.displayName ?? null}
                      </h1>
                      <p
                        id={"page_landingPage_description"}
                        className="text-m d-block mb-2"
                      >
                        {translatePage(
                          translations,
                          language,
                          "landingPage",
                          "description"
                        )}
                        <span
                          id={"page_landingPage_myPages"}
                          className="span-link text-extra-bold text-m "
                          onClick={() =>
                            openMyPages(dispatch, navigate, isAuthed)
                          }
                        >
                          {translatePage(
                            translations,
                            language,
                            "landingPage",
                            "myPages"
                          )}
                        </span>
                      </p>
                      <LanguageComponent></LanguageComponent>
                      <BookingFormV2 products={productData}></BookingFormV2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <div className="container min-vh-100">
                <div className="row justify-content-center min-vh-100">
                  <div className="col-auto">
                    <div className="startTimeDiv mt-5">
                      <LanguageComponent></LanguageComponent>
                      {language === "sv-SE" ? (
                        <>
                          <div className="bg-light p-3">
                            <h1>Tekniskt fel, kunde inte ladda biljetter.</h1>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="bg-light p-3">
                            <h1>Technical error, could not load tickets.</h1>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {productData ? (
            <div className="mt-4">
              <div className="container min-vh-100">
                <div className="row justify-content-center min-vh-100">
                  <div className="col-auto">
                    <div className="bookingFormContainer mt-5">
                      <h1 id={"page_landingPage_title"} className="d-inline">
                        {projectData?.translations?.find(
                          (translation) => translation.locale === language
                        )?.displayName ?? null}
                      </h1>
                      <p
                        id={"page_landingPage_description"}
                        className="text-m d-block mb-2"
                      >
                        {translatePage(
                          translations,
                          language,
                          "landingPage",
                          "description"
                        )}
                        <span
                          id={"page_landingPage_myPages"}
                          className="span-link text-extra-bold text-m "
                          onClick={() =>
                            openMyPages(dispatch, navigate, isAuthed)
                          }
                        >
                          {translatePage(
                            translations,
                            language,
                            "landingPage",
                            "myPages"
                          )}
                        </span>
                      </p>
                      <LanguageComponent></LanguageComponent>
                      <BookingFormComponent
                        products={productData}
                      ></BookingFormComponent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <div className="container min-vh-100">
                <div className="row justify-content-center min-vh-100">
                  <div className="col-auto">
                    <div className="startTimeDiv mt-5">
                      <LanguageComponent></LanguageComponent>
                      {language === "sv-SE" ? (
                        <>
                          <div className="bg-light p-3">
                            <h1>Tekniskt fel, kunde inte ladda biljetter.</h1>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="bg-light p-3">
                            <h1>Technical error, could not load tickets.</h1>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  } else {
    return null;
  }
}

export default LandingPageByProject;
