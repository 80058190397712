import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import moment from "moment";
// redux
import {
  setLoading,
  setSelectedOrder,
  setLoadingMessage,
} from "../../redux/actions";
import { getProtectedOrder } from "../../utils/api/restClient";

// components
import { LoadingComponent } from "../../components/layout/loading/LoadingComponent";
import InvoiceComponent from "../../components/custom/invoiceComponent";
import InvoiceComponentEuro from "../../components/custom/invoiceComponentEuro";
import TicketDetailsComponent from "../../components/order/receipt/ticketDetailsComponent";

//lang
import { translatePage } from "../../utils/services/translate";

// utils
import getCurrencyLabel from "../../utils/helper/currencyLabel";

// scss
import "./publicOrderPage.scss";
import TaxComponent from "../../components/receipt/taxComponent";

function PublicOrderPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const order = useSelector((state) => state.Order.selected_order);
  const loading = useSelector((state) => state.Layout.loading);
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const { referenceId } = useParams();
  const [expandedRow, setExpandedRow] = useState(null);

  const getOrderFromApi = useCallback(
    async (referenceId) => {
      try {
        dispatch(dispatch(setLoadingMessage("Laddar..", "Loading..")));
        const res = await getProtectedOrder(referenceId);
        if (res.status === "paid") {
          dispatch(setSelectedOrder(res));
        } else {
          dispatch(setSelectedOrder(null));
        }
        dispatch(setLoading(false));
      } catch (error) {
        console.error("Error fetching order:", error);
        dispatch(setLoading(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getOrderFromApi(referenceId);
  }, [getOrderFromApi, referenceId]);

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const orderContainsTickets = (order) => {
    if (order.tickets !== null) {
      return true;
    }
    return false;
  };

  const getProductPrice = (item, order, language) => {
    let currency = "kr";
    if (order.currency !== null) {
      currency = getCurrencyLabel(language, order.currency);
    } else {
      if (language !== "sv-SE") {
        currency = "SEK";
      }
    }

    var i = item.price + " " + currency;
    if (item.status !== "refunded") {
      return <span className="text-m">{i}</span>;
    } else {
      let refundedItem = "-" + item.price + " " + currency;
      return (
        <span className="text-m text-extra-bold text-danger">
          {refundedItem}
        </span>
      );
    }
  };
  const getOrderPrice = (order, language) => {
    let currency = "kr";
    if (order.currency !== null) {
      currency = getCurrencyLabel(language, order.currency);
    } else {
      if (language !== "sv-SE") {
        currency = "SEK";
      }
    }

    var i = order.currentAmount + ".00 " + currency;
    return i;
  };
  const getOrderStatus = (order, language, translations) => {
    switch (order.status) {
      case "paid":
        return (
          <span className="text-success text-extra-bold">
            {translatePage(
              translations,
              language,
              "publicOrderPage",
              "orderStatusPaidText"
            )}
          </span>
        );

      case "inProgress":
        return (
          <span className="text-success text-extra-bold">
            {translatePage(
              translations,
              language,
              "publicOrderPage",
              "orderStatusInprogressText"
            )}
          </span>
        );
      case "abandoned":
        return (
          <span className="text-success text-extra-bold">
            {translatePage(
              translations,
              language,
              "publicOrderPage",
              "orderStatusAbandonedText"
            )}
          </span>
        );
      case "deleted":
        return (
          <span className="text-success text-extra-bold">
            {translatePage(
              translations,
              language,
              "publicOrderPage",
              "orderStatusDeletedText"
            )}
          </span>
        );
      default:
        return order.status;
    }
  };
  const getNegativeValue = (item) => {
    if (item.status === "refunded") {
      return -1;
    }
    return 0;
  };

  const ticketWarningMessage = (order) => {
    let orderItemTicketCount = order.items.reduce(
      (count, item) => count + (item.productType !== "merchandise" ? 1 : 0),
      0
    );
    let ticketCount = (order.tickets || []).length;
    if (orderItemTicketCount !== ticketCount) {
      return (
        <div className="bg-warning p-4 rounded text-center">
          <h1>
            {translatePage(
              translations,
              language,
              "publicOrderPage",
              "ticketWarningMessageH1"
            )}
          </h1>
          <p>
            {translatePage(
              translations,
              language,
              "publicOrderPage",
              "ticketWarningMessageP"
            )}
          </p>
          <p className="text-extra-bold">
            {translatePage(
              translations,
              language,
              "publicOrderPage",
              "ticketWarningMessageP2"
            )}
          </p>
        </div>
      );
    }
  };
  const ticketsButton = (order) => {
    if (order.items.some((item) => item.productType !== "merchandise")) {
      return (
        <button
          className="btn btn-success d-inline text-m w-100"
          onClick={() => navigate("/t/" + order.referenceId)}
        >
          {translatePage(
            translations,
            language,
            "publicOrderPage",
            "ticketsButtonText"
          )}
        </button>
      );
    } else {
      return null;
    }
  };

  const getIcon = (orderItem) => {
    let ticket = findTicketByReferenceId(orderItem.referenceId);
    if (ticket === null) {
      return <span className="text-danger">?</span>;
    } else {
      if (orderItem.status === "refunded") {
        return <span></span>;
      }
      if (
        orderItem.status === "pendingRefund" ||
        orderItem.status === "refundFailed" ||
        orderItem.status === "refunded"
      ) {
        return <span className="ticket-icon-warning"></span>;
      }
      if (ticket.status === "valid" || ticket.status === "activated") {
        return <span className="ticket-icon-success"></span>;
      }
      if (ticket.status === "used" || ticket.status === "expired") {
        return <span className="ticket-icon-danger"></span>;
      }
      return null;
    }
  };

  const formatDate = (date) => {
    try {
      return moment(date).format("YYYY-MM-DD HH:mm");
    } catch {
      return "";
    }
  };
  const getProductName = (product, language) => {
    let ticketTranslation = product.translations.find(
      (x) => x.locale === language
    );
    if (ticketTranslation === undefined || ticketTranslation === null) {
      if (product.description === "") {
        return (
          <>
            <p className="text-sm mt-2  mb-2 pt-1 text-left">{product.name}</p>
          </>
        );
      } else {
        return (
          <>
            <p className="text-sm mt-2  mb-2 pt-1 text-left">
              {product.name} - {product.description}
            </p>
          </>
        );
      }
    } else {
      if (
        ticketTranslation.name !== product.name ||
        ticketTranslation.description !== product.description
      ) {
        if (product.description === "") {
          return (
            <>
              <p className="text-sm p-0 m-0 pt-1 text-left">{product.name}</p>
              <p className="text-sm p-0 m-0 pb-1 text-muted">
                ( {ticketTranslation.name} )
              </p>
            </>
          );
        } else {
          return (
            <>
              <p className="text-sm p-0 m-0 pt-1 text-left">
                {product.name} - {product.description}
              </p>
              <p className="text-sm p-0 m-0 pb-1 text-muted">
                ( {ticketTranslation.name} - {ticketTranslation.description} )
              </p>
            </>
          );
        }
      }
      if (product.description === "") {
        return (
          <>
            <p className="text-m p-0 m-0 text-left">{product.name}</p>
          </>
        );
      } else {
        return (
          <>
            <p className="text-m p-0 m-0 text-left">{product.name}</p>
            <span className="text-sm pt-0 mt-0">{product.description}</span>
          </>
        );
      }
    }
  };

  const findTicketByReferenceId = (referenceId) => {
    try {
      return order.tickets.find((ticket) => ticket.referenceId === referenceId);
    } catch {
      return null;
    }
  };

  if (loading) {
    return <LoadingComponent />;
  }
  if (order === null) {
    return (
      <div className="container min-vh-100 mt-5 pt-5">
        <div className="row">
          <div className="col-12 text-center bg-light rounded p-4">
            <h1>
              {translatePage(
                translations,
                language,
                "publicOrderPage",
                "orderNotFoundMessage"
              )}
            </h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="order-container container mt-5 pt-4">
        <div className="col-12">{ticketWarningMessage(order)}</div>
        <div className="col-12">
          {orderContainsTickets(order) ? (
            <>
              {" "}
              <Link
                id={"page_landingPage_myPages"}
                className="text-dark pe-2 text-decoration-none text-m"
                to={"/t/" + order.referenceId}
              >
                {translatePage(
                  translations,
                  language,
                  "publicOrderPage",
                  "ticketsLinkText"
                )}
              </Link>
              <span className="p text-lightgreen text-m pe-2">/</span>
            </>
          ) : null}

          <span className="text-muted text-m">
            {" "}
            {translatePage(
              translations,
              language,
              "publicOrderPage",
              "ticketsReceiptLinkText"
            )}
          </span>
        </div>
      </div>

      <div className="order-container container min-vh-100 pt-2">
        <div className="border p-1 rounded">
          <div className="container">
            <div className="row pe-2 pt-4 pb-2">
              <div className="col-6"> </div>
              <div className="col-6  text-end">
                {" "}
                {order.currency === "SEK" ? (
                  <InvoiceComponent order={order} lang={language} />
                ) : (
                  <InvoiceComponentEuro order={order} lang={language} />
                )}
              </div>
            </div>
          </div>
          <div className="border-bottom pb-2">
            <div className="order-details mt-4 bg-light p-3 rounded">
              <span className="text-extra-bold">
                {" "}
                {translatePage(
                  translations,
                  language,
                  "publicOrderPage",
                  "orderDetailsOrderNumberText"
                )}
                :{" "}
              </span>{" "}
              <span className="">{order.id}</span>
              <br />
              <span className="text-extra-bold">
                {translatePage(
                  translations,
                  language,
                  "publicOrderPage",
                  "statusText"
                )}
                :{" "}
              </span>{" "}
              {getOrderStatus(order, language, translations)}
              <br />
              <span className="text-extra-bold">
                {translatePage(
                  translations,
                  language,
                  "publicOrderPage",
                  "orderDetailsOrderDateText"
                )}
                :{" "}
              </span>{" "}
              <span>{formatDate(order.paidDateTime)}</span>
              <br />
              <span className="text-extra-bold">
                {translatePage(
                  translations,
                  language,
                  "publicOrderPage",
                  "orderDetailsOrderIdentityText"
                )}
                :
              </span>{" "}
              <span>{order.phoneNumber}</span>
              <br />
              <div className="total-amount">
                <p className="text-l pb-0 m-0 text-center">
                  <strong>
                    {translatePage(
                      translations,
                      language,
                      "publicOrderPage",
                      "orderDetailsOrderTotalAmountText"
                    )}
                    :
                  </strong>{" "}
                  {getOrderPrice(order, language)}
                </p>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    {translatePage(
                      translations,
                      language,
                      "publicOrderPage",
                      "orderTableHeaderProduct"
                    )}
                  </th>
                  <th className="">
                    <span className="btn-info-small"></span>
                  </th>
                  <th>
                    {translatePage(
                      translations,
                      language,
                      "publicOrderPage",
                      "orderTableHeaderPrice"
                    )}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.productType !== "merchandise" ? (
                      <tr
                        onClick={() => toggleRow(index)}
                        className={`order-table-row ${
                          expandedRow === index ? "active-row" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <td
                          className={`product-max-width ${
                            getNegativeValue(item) < 0 ? "negative-value" : ""
                          }`}
                        >
                          {getProductName(item, language)}
                        </td>
                        <td>{getIcon(item)}</td>
                        <td>{getProductPrice(item, order, language)}</td>
                        <td>
                          {item.productType !== "merchandise" ? (
                            <span
                              className={`chevron-btn-right ${
                                expandedRow === index
                                  ? "chevron-btn-down"
                                  : "chevron-btn-right"
                              }`}
                            ></span>
                          ) : null}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          className={`product-max-width ${
                            getNegativeValue(item) < 0 ? "negative-value" : ""
                          }`}
                        >
                          <span>
                            {" "}
                            {item.name} {item.description}
                          </span>
                        </td>
                        <td className="p-0 m-0 text-center">
                          <span></span>
                        </td>
                        <td>{getProductPrice(item, order, language)}</td>
                        <td>
                          <span></span>
                        </td>
                      </tr>
                    )}

                    {expandedRow === index && (
                      <tr>
                        <td colSpan="4">
                          <div className="p-2">
                            {TicketDetailsComponent(
                              item,
                              order,
                              language,
                              translations,
                              true
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <TaxComponent></TaxComponent>
          <div className="col-12 pb-3 pt-2 text-l">{ticketsButton(order)}</div>
        </div>
      </div>
    </>
  );
}

export default PublicOrderPage;
