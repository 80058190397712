import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import EmptyPageLang from "./EmptyPageLang";
import "./EmptyPage.scss";
import { getDefaultProject } from "../utils/localStorage";
import { LoadingComponent } from "../components/layout/loading/LoadingComponent";

function EmptyPage() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState("sv-SE");

  const handleDefaultProject = useCallback(async () => {
    const project = getDefaultProject();
    if (project) {
      setLoading(false);
      navigate(project.slug);
    } else {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    handleDefaultProject();
  }, [handleDefaultProject]);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const projects = [
    {
      title: "Diggiloobussen",
      descriptions: {
        "sv-SE": "",
        "en-US": "",
        "fi-FI": "",
      },
      slug: "/diggiloobussen",
      button_texts: {
        "sv-SE": "Köp biljetter",
        "en-US": "Buy tickets",
        "fi-FI": "Osta liput",
      },
      status: "ongoing",
    },
    {
      title: "Sweden Rock",
      descriptions: {
        "sv-SE":
          "Tack för i år! Hoppas du haft en kul musikfest. Det har vi. Sista bussen har gått för den här gången och vi säljer därför inte biljetter längre. Till och med 11 september 2024 kan du logga in på Mina sidor för att se dina köp och kvitton. Efter det raderar vi dina uppgifter. Hoppas vi ses igen på Sweden Rock 2025!",
        "en-US":
          "Thank you for this year! We hope you had a great music festival. We did. The last bus has departed for this time and we are no longer selling tickets. Until September 11, 2024, you can log in to Mina sidor to see your purchases and receipts. After that, we will delete your data. Hope to see you again at Sweden Rock 2025!",
        "fi-FI":
          "Kiitos tästä vuodesta! Toivottavasti sinulla oli hauska musiikkifestivaali. Meillä oli. Viimeinen bussi on lähtenyt tältä erää ja emme myy enää lippuja. Syyskuun 11. päivään 2024 asti voit kirjautua sisään Mina sidor nähdäksesi ostoksesi ja kuitit. Sen jälkeen poistamme tietosi. Toivottavasti näemme jälleen Sweden Rock 2025!",
      },
      slug: "/swedenrock",
      button_texts: {
        "sv-SE": "Avslutat",
        "en-US": "Ended",
        "fi-FI": "Päättynyt",
      },
      status: "completed",
    },
    {
      title: "Saaristo",
      descriptions: {
        "sv-SE":
          "Tack för i år! Sista bussen har gått för den här gången och vi säljer därför inte biljetter längre. Till och med 11 september 2024 kan du logga in på Mina sidor för att se dina köp och kvitton. Efter det raderar vi dina uppgifter.",
        "en-US":
          "Thank you for this year! The last bus has departed for this time and we are no longer selling tickets. Until September 11, 2024, you can log in to Mina sidor to see your purchases and receipts. After that, we will delete your data.",
        "fi-FI":
          "Kiitos tästä vuodesta! Viimeinen bussi on lähtenyt tältä erää ja emme myy enää lippuja. Syyskuun 11. päivään 2024 asti voit kirjautua sisään Mina sidor nähdäksesi ostoksesi ja kuitit. Sen jälkeen poistamme tietosi.",
      },
      slug: "/saaristo",
      button_texts: {
        "sv-SE": "Avslutat",
        "en-US": "Ended",
        "fi-FI": "Päättynyt",
      },
      status: "completed",
    },
  ];

  const categorizedProjects = projects.reduce(
    (acc, project) => {
      acc[project.status].push(project);
      return acc;
    },
    { ongoing: [], completed: [] }
  );

  if (loading) return <LoadingComponent />;

  return (
    <div className="container min-vh-100 mt-5 pt-5 text-center">
      <h1>Nobina-Biljetten</h1>
      <EmptyPageLang onLanguageChange={handleLanguageChange} />
      {categorizedProjects.ongoing.length > 0 && (
        <div>
          <h2>Pågående event</h2>
          <div className="row justify-content-center">
            {categorizedProjects.ongoing.map((project, index) => (
              <ProjectCard key={index} project={project} language={language} />
            ))}
          </div>
        </div>
      )}
      {categorizedProjects.completed.length > 0 && (
        <div>
          <h2>Tidigare event</h2>
          <div className="row justify-content-center">
            {categorizedProjects.completed.map((project, index) => (
              <ProjectCard key={index} project={project} language={language} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const ProjectCard = ({ project, language }) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="col-12 mb-4 d-flex">
      <div
        className="card card-equal-height w-100"
        style={{ borderRadius: "15px", border: "2px solid green" }}
      >
        <div className="card-body d-flex flex-column">
          <h2 className="card-title">
            <button
              className="btn btn-link"
              onClick={() => setIsExpanded(!isExpanded)}
              style={{ textDecoration: "none", fontSize: "1.5rem" }}
            >
              {project.title}
            </button>
          </h2>
          {isExpanded && (
            <>
              <p className="card-text">{project.descriptions[language]}</p>
              <div className="mt-auto">
                {project.status === "ongoing" ? (
                  <button
                    className="btn btn-success w-100"
                    onClick={() => navigate(project.slug)}
                  >
                    {project.button_texts[language]}
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-secondary w-100"
                      style={{ backgroundColor: "grey", borderColor: "grey" }}
                      disabled
                    >
                      {project.button_texts[language]}
                    </button>
                    <button
                      className="btn btn-success w-100 mt-2"
                      onClick={() => navigate("/home/orders")}
                    >
                      Mina sidor
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmptyPage;
