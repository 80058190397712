import React, { useState } from "react";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import moment from "moment";

//components
import { TicketConfirmationDialog } from "./ticketConfirmationDialog";
import { TicketConfirmationDialogPlaceHolder } from "./ticketConfirmationDialogPlaceHolder";
import { ValidationInformationDialog } from "./validationInformationDialog";
import { TicketErrorDialog } from "./ticketErrorDialog";
import { TicketInformationDialog } from "./ticketInformationDialog";
import { translateComponent } from "../../utils/services/translate";

// img
import NobinaBiljettenImg from "../../assets/img/logo/Nobina-biljetten_logo.svg";
import NobinaBiljettenImgGrayedOut from "../../assets/img/logo/Nobina-biljetten_logo_greyed.svg";

// scss
import "./ticketTemplate.scss";

export function TicketTemplatePlaceHolder({
  background,
  ticket,
  onActivateClick,
  dialogOpen,
  errorOpen,
  informationOpen,
  validationOpen,
  handleValidationInformationClose,
  handleInformationClose,
  handleDialogClose,
  handleDialogConfirm,
  handleErrorClose,
  ticketHeader,
  ticketStatus,
  ticketInformation,
  showActivateButton,
  fraudProtection,
  activationColor,
}) {
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const [showActiveTicket, setShowActiveTicket] = useState(false);

  const peekActiveTicket = () => {
    setShowActiveTicket(true);
    setTimeout(() => {
      setShowActiveTicket(false);
    }, 5000);
  };
  return (
    <div className={`ticket-container mb-5 mt-5 ${background}`}>
      {dialogOpen ? (
        <div className="ticket-confirmation-container ps-4 pe-4">
          <TicketConfirmationDialogPlaceHolder
            countdownValidationSeconds={ticket.countdownValidationSeconds}
            isOpen={dialogOpen}
            onClose={handleDialogClose}
            onConfirm={handleDialogConfirm}
          />
        </div>
      ) : null}
      {errorOpen ? (
        <TicketErrorDialog isOpen={errorOpen} onClose={handleErrorClose} />
      ) : null}
      {informationOpen ? (
        <div className="ticket-confirmation-container ps-4 pe-4">
          <TicketInformationDialog
            ticket={ticket}
            isOpen={informationOpen}
            onClose={handleInformationClose}
          />
        </div>
      ) : null}
      {validationOpen && ticket.maxAllowedAttempts > 1 ? (
        <div className="ticket-confirmation-container ps-4 pe-4">
          <ValidationInformationDialog
            ticket={ticket}
            isOpen={validationOpen}
            onClose={handleValidationInformationClose}
          />
        </div>
      ) : null}
      {!dialogOpen && !errorOpen && !informationOpen && !validationOpen ? (
        <>
          <div className="ticket-header container">
            <div className="row">
              <div className="col-12">{ticketHeader(ticket)}</div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                maxWidth: "180px",
              }}
            >
              {ticket.status === "activated" ? (
                <>
                  {showActiveTicket ? (
                    <div className={`content mt-3 ${background}`}>
                      <div
                        className="ticket-border"
                        style={{
                          border: `5px solid ${activationColor}`,

                          backgroundColor: activationColor,
                        }}
                      >
                        <QRCode
                          size={180}
                          style={{
                            height: "180px",
                            maxWidth: "180px",
                            padding: "10px",
                            background: "white",
                            display: "block",
                          }}
                          value={"https://ticket/id/" + ticket.referenceNumber}
                          viewBox={`0 0 50 50`}
                        ></QRCode>

                        {ticketStatus(ticket)}
                        {ticketInformation(ticket)}

                        {showActivateButton ? (
                          <div className=" text-dark mt-3 mb-0 text-m text-center w-100  mb-3">
                            <button
                              className="btn btn-success text-white ticket-activate-button"
                              onClick={onActivateClick}
                            >
                              {translateComponent(
                                translations,
                                language,
                                "ticket",
                                "activateTicketButton"
                              )}{" "}
                            </button>
                          </div>
                        ) : (
                          <>
                            {!fraudProtection ? (
                              <div className="ticket-activate-button-placeholder">
                                <div className="bg-dark">
                                  <p className="text-m p-2">
                                    {translateComponent(
                                      translations,
                                      language,
                                      "ticket",
                                      "ticketControlVisible"
                                    )}{" "}
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={`content mt-3 `}>
                      {ticketStatus(ticket)}
                      {ticketInformation(ticket)}
                      {fraudProtection ? null : (
                        <div className="mt-3">
                          <button
                            className="btn btn-secondary text-m"
                            onClick={() => {
                              peekActiveTicket(setShowActiveTicket);
                            }}
                          >
                            {translateComponent(
                              translations,
                              language,
                              "ticket",
                              "ticketControlButtonText"
                            )}{" "}
                          </button>
                          <div className="bg-dark text-white text-start mt-3">
                            <p className="text-m p-2">
                              {translateComponent(
                                translations,
                                language,
                                "ticket",
                                "ticketControlText"
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      )}

                      {showActivateButton ? (
                        <div className=" text-dark mt-3 mb-0 text-m text-center w-100  mb-3">
                          <button
                            className="btn btn-success text-white ticket-activate-button"
                            onClick={onActivateClick}
                          >
                            {translateComponent(
                              translations,
                              language,
                              "ticket",
                              "activateTicketButton"
                            )}{" "}
                          </button>
                        </div>
                      ) : (
                        <div className="ticket-activate-button-placeholder"></div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className={`content mt-3 ${background}`}>
                    <div className="bg-light p-4 text-dark bg-opacity-10">
                      {ticket.status !== "valid" ? (
                        <img
                          className="bg-opacity-10"
                          src={NobinaBiljettenImgGrayedOut}
                          alt="Description"
                        />
                      ) : (
                        <>
                          <img
                            className="bg-opacity-10"
                            src={NobinaBiljettenImg}
                            alt="Description"
                          />
                        </>
                      )}
                    </div>
                    {ticketStatus(ticket)}
                    {ticketInformation(ticket)}
                    {showActivateButton ? (
                      <div className=" text-dark mt-3 mb-0 text-m text-center w-100  mb-3">
                        <button
                          className="btn btn-success text-white ticket-activate-button"
                          onClick={onActivateClick}
                        >
                          {translateComponent(
                            translations,
                            language,
                            "ticket",
                            "activateTicketButton"
                          )}{" "}
                        </button>
                        <p className="text-m m-0 p-0 pt-2 text-extra-bold">
                          {translateComponent(
                            translations,
                            language,
                            "ticket",
                            "activateBefore"
                          )}{" "}
                        </p>
                        <p className="text-m p-0 m-0 line-override">
                          {" "}
                          {moment(ticket.expirationDate).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </p>
                      </div>
                    ) : (
                      <div className="ticket-activate-button-placeholder"></div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
