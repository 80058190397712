import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProduct, setBasketData } from "../../redux/actions";
import ProductDescription from "./productDescription";
import "./styles.scss";
import MySvg from "../../assets/icons/buttons/Btn_info.svg";
import { translateComponent } from "../../utils/services/translate";

import { getCurrencyLabel } from "../../utils/helper/currencyLabel";

const Products = () => {
  const dispatch = useDispatch();
  const basket = useSelector((state) => state.Order.basket);
  const language = useSelector((state) => state.Layout.language);
  const translations = useSelector((state) => state.Layout.translations);
  const project = useSelector((state) => state.Project.project);
  const product_list = useSelector((state) => state.Product.product_list);
  const selected_product = useSelector(
    (state) => state.Product.selected_product
  );

  const initialProductQuantities = {};
  product_list.forEach((product) => {
    const productInBasket = basket.products.find(
      (item) => item.id === product.id
    );
    initialProductQuantities[product.id] = productInBasket
      ? productInBasket.quantity
      : 0;
  });
  const [productQuantities, setProductQuantities] = useState(
    initialProductQuantities
  );
  const productHandler = (productId, action) => {
    setProductQuantities((prevQuantities) => {
      const currentValue = prevQuantities[productId];
      let newValue = currentValue;

      if (action === "up" && currentValue < 9) {
        newValue = currentValue + 1;
      } else if (action === "down" && currentValue > 0) {
        newValue = currentValue - 1;
      }

      const updatedQuantities = { ...prevQuantities, [productId]: newValue };
      updateBasket(updatedQuantities);
      return updatedQuantities;
    });
  };

  const updateBasket = (updatedQuantities) => {
    let tempBasket = {
      basketValid: false,
      phoneValid: basket.phoneValid,
      phone: basket.phone,
      total: 0,
      products: [],
    };

    product_list.forEach((product) => {
      const quantity = updatedQuantities[product.id];
      if (quantity > 0) {
        const updatedProduct = { ...product, quantity };
        tempBasket.products.push(updatedProduct);
        tempBasket.basketValid = true;
      }
    });

    let total = tempBasket.products.reduce(
      (acc, product) => acc + product.quantity * product.price,
      0
    );
    tempBasket.total = total;
    dispatch(setBasketData(tempBasket));
  };

  if (!product_list) {
    return <p className="text-center">Loading...</p>;
  }

  return (
    <div className="row">
      <div className={`col-12`}>
        {product_list.map((product) => {
          const translation = product.translations.find(
            (t) => t.locale === language
          );
          if (!translation) return null;
          return (
            <div key={product.id} className="product-container">
              <div className="details-actions-wrapper">
                <div className="product-details">
                  <p className="text-extra-bold mb-0">{translation.name}</p>
                  <span
                    className="text-m"
                    onClick={() =>
                      dispatch(
                        setSelectedProduct(
                          selected_product === product ? null : product
                        )
                      )
                    }
                  >
                    {translation.description === "" ? (
                      <span className="p-0 m-0 text-m pe-2">
                        {translateComponent(
                          translations,
                          language,
                          "products",
                          "showInformation"
                        )}
                      </span>
                    ) : (
                      <span className="p-0 m-0">
                        ({translation.description})&nbsp;&nbsp;&nbsp;
                      </span>
                    )}
                    <img height="18" width="20" src={MySvg} alt="information" />
                  </span>
                </div>
                <div className="product-actions-container">
                  <div className="product-actions pt-1 m-0">
                    <span
                      className="btn-remove me-1"
                      onClick={() => productHandler(product.id, "down")}
                    ></span>
                    <input
                      type="text"
                      id={"productId-" + product.id}
                      className="product-count text-center"
                      value={productQuantities[product.id]}
                      readOnly
                    />
                    <span
                      className="btn-add ms-1"
                      onClick={() => productHandler(product.id, "up")}
                    ></span>
                  </div>
                  <div className="mt-2">
                    <p className="product-price">
                      {product.price}{" "}
                      {getCurrencyLabel(language, project.currency)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="product-description-container">
                {selected_product != null &&
                selected_product.id === product.id ? (
                  <ProductDescription
                    product={selected_product}
                    language={language}
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Products;
